@import '../../styles/variables';
@import '../../styles/mixin';

.bundle_empty_message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 0.5rem;
  user-select: none;
  padding-block: 1rem;

  .bundle_empty_message__title {
    font-weight: 500;
  }

  .bundle_empty_message__subtitle {
    font-size: $text-sm;
  }

  svg {
    * {
      stroke: $bundle-special-icon-default;
    }
  }
}

.products_preview_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .clear_all_button {
    font-size: $text-sm;
    font-weight: 700;
    padding: 0;
    height: auto;
    width: fit-content;
    counter-reset: $select-menu-item-color;
    align-self: end;
    border-radius: 0;
    border-bottom: 2px solid $select-menu-item-color;

    @include media(sm) {
      font-size: $text-base;
    }
  }

  .products_preview {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .preview_item {
      padding-block: 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 2rem;

      &__left {
        display: flex;
        gap: 1.25rem;
        align-items: center;
      }

      &__right {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }

      &__image {
        width: 55px;
        height: 55px;
        position: relative;
        flex-shrink: 0;

        @include media(sm) {
          width: 96px;
          height: 96px;
        }
      }

      &__info {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        margin-bottom: 0.5rem;
        text-wrap: balance;

        &_details {
          display: flex;
          flex-wrap: wrap;
          gap: 0.5rem;
          align-items: center;

          .info_details_discount {
            font-size: $text-xs;
            line-height: 1.25;
            background-color: $white;
            color: $black;
            border: 1px solid $gray-100;
            padding: 0.25rem 0.75rem;
            font-weight: 500;
            flex-shrink: 0;
          }
        }

        &_name {
          font-size: $text-xs;
          line-height: 1.5;
          font-weight: 500;
          color: $select-menu-item-color;
          max-width: 274px;

          @include media(sm) {
            font-size: $text-base;
          }
        }
      }

      &__info_skeleton {
        height: 25px;
        width: 220px;
      }

      &__counter {
        display: flex;
        align-items: center;
        border: 0.64px solid $detail-counter-border;
        background-color: $counter-bacground;
        border-radius: $counter-border-radius;
        width: fit-content;
        height: 100%;
        color: $counter-text;
        font-weight: 700;
        font-size: $text-xs;
        line-height: 1rem;

        @include media(sm) {
          font-size: $text-sm;
        }

        .counter__count {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 26px;
          padding: 4px;
          height: 26px;
          line-height: 20.44px;
          background-color: $counter-bacground-count !important;

          @include media(sm) {
            width: 32px;
            padding: 8px;
            height: 32px;
          }
        }

        .counter__operation {
          height: 26px;
          padding: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: right;
          color: $counter-operation-color;
          background-color: $counter-bacground !important;
          border-radius: $counter-border-radius;

          @include media(sm) {
            height: 32px;
            padding: 8px;
          }

          svg {
            width: 16px;
            height: 16px;

            * {
              stroke: $counter-operation-color;
            }
          }

          &:hover,
          &:focus-within {
            svg {
              * {
                stroke: $counter-operation-color-hover;
              }
            }
          }
        }
      }

      &__counter_skeleton {
        height: 34px;
        width: 98px;
      }

      &__price {
        del {
          font-size: $text-xs;
          line-height: 1;
          text-decoration-line: line-through;
          color: $discount-price;
        }

        ins {
          font-weight: 500;
          line-height: 1;
          font-size: $text-sm;
          text-decoration: none;
          color: $select-menu-item-color;

          @include media(sm) {
            font-size: $text-lg;
          }
        }
      }

      &__price_skeleton {
        height: 34px;
        width: 65px;
        margin-left: 1rem;
      }

      .remove_button {
        width: 32px;
        height: 32px;
        padding: 0;
        flex-shrink: 0;
        color: $label-btn-link;
        border-radius: calc($btn-close-border-radius);
        margin-left: 1rem;

        &:hover {
          background-color: darken($select-menu-bg, 5%);
          color: $label-btn-link-hove; 
        }

        &__skeleton {
          width: 32px;
          height: 32px;
          margin-left: 1rem;
          border: none;
          border-radius: 0.25rem;
        }
      }
    }
  }
}
