@import '../../styles/variables';
@import '../../styles/mixin';

.specialbanner {
  text-align: center;
  margin-bottom: 32px;

  @include media(lg) {
    text-align: left;
  }

  @include media(2xl) {
    max-width: 60%;
  }

  &__title {
    color: $bundle-specials-title-color !important;
    text-align: center;

    @include media(lg) {
      text-align: left;
    }

    &--placeholder {
      height: 52px;
      width: 80%;
    }
  }

  p {
    color: $bundle-spacials-descriptions !important;
  }

  span {
    color: $badge-text !important;
  }
}
