@import '../../styles/variables';
@import '../../styles/mixin';

.modal {
  border-radius: $border-radius;

  div[class~='modal__content'] {
    background-color: $card-validator-background;
  }

  .store_validator {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    .med_form__row {
      display: flex;
      flex-direction: column;
      width: 100%;
      row-gap: 1rem;
      margin-bottom: 1rem;
    }

    &__header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .header_title {
        font-weight: 500;
        font-size: $text-xl;
        line-height: 1.5rem;
        color: $gray-600;
      }
    }

    .warning_message {
      margin: 0;
    }

    &__footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 12px;
      //padding-top: 1rem;

      button {
        width: 100%;
        text-transform: uppercase;
      }
    }
  }
}
