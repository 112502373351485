@import '../../styles/variables';
@import '../../styles/mixin';

.container {
  p {
    color: $gray-300;

    @include media(sm) {
      font-size: $text-xl;
      line-height: 26px;
    }
  }

  .text-title {
    font-weight: 700px;
    color: $gray-500;
  }

  .headding {
    font-size: calc(1.4rem + 1.5vw);
    line-height: calc(1.9rem + 1.5vw);
    font-weight: 700;
    color: $gray-500;

    @include media(2xl) {
      font-size: $text-5xl;
    }
  }

  .layout__flex_column {
    display: flex;
    flex-direction: column;
  }

  .layout__flex_row {
    display: flex;
    flex-direction: row;
  }

  .content {
    @extend .layout__flex_row;
    align-items: center;
    position: relative;
    padding: 0 20px;
    gap: 53px;

    @include media(sm) {
      padding: 0 40px;
    }

    @include media(xl) {
      padding: 0 90px;
    }

    @include media(2xl) {
      padding: 0 107px;
    }

    @include media(3xl) {
      padding: 0 144px;
    }
  }

  .content__text {
    @extend .layout__flex_column;
    align-items: center;
    margin: auto 0;

    @media screen and (max-width: 1024px) {
      width: 100%;
      text-align: center !important;
      align-items: center !important;
    }

    p {
      color: $gray-400;
      order: 3;
    }
  }

  .content__text_info {
    @extend .layout__flex_column;
    text-align: left;
    gap: 16px;
  }

  .content__header {
    @extend .layout__flex_column;
    gap: 16px;
  }

  .body {
    @extend .layout__flex_column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 40px;

    &__default {
      p {
        font-size: $text-base !important;
        line-height: 20px;
      }
    }
  }

  .body__left_aligned_large {
    @extend .layout__flex_column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    gap: 40px;

    @include media(md) {
      flex-direction: row;
      align-items: flex-start;
      gap: 32px;
    }

    @include media(lg) {
      width: 95%;
    }

    .content__text_info {
      order: 1;
    }

    figure {
      order: 0;
      place-self: initial;

      @include media(sm) {
        margin-top: 23px;
      }
    }

    p {
      order: 3;
      margin-bottom: 0;
      letter-spacing: -0.3px;

      @include media(sm) {
        line-height: 30px;
      }
    }

    .body__title {
      font-size: $text-2xl;

      @include media(sm) {
        font-size: calc(0.74rem + 1.5vw);
        line-height: calc(1rem + 1.5vw);
      }
    }

    .body__text_heading {
      margin: 0 initial;
    }
  }

  .body__left_aligned_large_headline {
    @extend .body__left_aligned_large;

    @include media(md) {
      flex-direction: column;
      align-items: flex-start;
      width: 98%;
    }

    .content__header {
      @include media(md) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 72px;
      }

      @include media(2xl) {
        gap: 107px;
      }

      @include media(3xl) {
        gap: 144px;
      }
    }

    .body__title {
      font-size: calc(1.4rem + 1.5vw);
      line-height: calc(1.9rem + 1.5vw);
      font-weight: 700;
      color: $gray-500;

      @include media(2xl) {
        font-size: $text-5xl;
      }
    }

    figure {
      margin-top: 0;
    }

    a {
      margin-top: 16px;
    }
  }

  .body__right_aligned_large {
    @extend .body__left_aligned_large;

    .content__text_info {
      @include media(md) {
        order: 0;
      }
    }

    figure {
      @include media(md) {
        margin-top: 23px;
        order: 1;
      }
    }
  }

  .body__center_icon_row {
    margin: 0 auto;
    align-items: center;
    text-align: center;

    @include media(lg) {
      max-width: 76%;
    }

    .body__title {
      max-width: 540px;
      font-size: $text-2xl;
    }

    p {
      margin-top: 16px;
    }
  }

  .body__center_icon_row_single {
    @extend .layout__flex_column;
    margin: 0 auto;
    gap: 16px;
    align-items: center;
    text-align: center;

    @include media(lg) {
      max-width: 76%;
    }

    figure {
      order: 0;
    }

    .content__text {
      order: 1;
    }

    p {
      order: 3;
    }
  }

  .body__center_icon_row_single_title {
    @extend .body__center_icon_row_single;

    figure {
      margin-bottom: 32px;
    }
  }

  .body__text_heading {
    font-size: $text-lg !important;
    font-weight: 600;
    color: $gray-300;
    margin-top: 40px;
    margin-bottom: 12px;
    letter-spacing: 2%;

    @media screen and (min-width: 420px) {
      margin-top: 0;
    }
  }

  .body__title {
    @extend .headding;
    order: 1;
  }

  .body__contents_images {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 40px;

    @include media(sm) {
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    }

    @include media(xl) {
      gap: 90px;
    }

    @include media(2xl) {
      gap: 75px;
    }

    @include media(3xl) {
      gap: 192px;
    }

    &_small {
      gap: 0;
      grid-template-columns: repeat(3, minmax(0, 1fr));

      @include media(sm) {
        width: 90%;
      }
    }
  }

  .body__figure_round {
    position: relative;
    border-radius: 100%;
    place-self: center;
    width: 50%;
    padding-top: 50%;
    overflow: hidden;
    background-color: $gray-50;

    @include media(sm) {
      width: 100%;
      padding-top: 100%;
    }

    @include media(2xl) {
      max-width: 89%;
      padding-top: 89%;
    }

    img {
      border-radius: 100%;
      height: auto;
    }

    i {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 22px;
      color: $gray-300;
    }
  }

  .body__figure_single {
    @extend .body__figure_round;
    width: 100%;
    max-width: 104px;
    padding-top: 104px;

    img {
      height: auto;
    }
  }

  .body__figure_icon_multiple {
    @extend .body__figure_round;
    width: 100%;
    gap: 40px;
    max-width: 60px;
    padding-top: 60px;

    @include media(sm) {
      padding-top: 80px;
      max-width: 80px;
    }

    img {
      height: auto;
    }
  }

  .body__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 8px;

    .body__item_title {
      font-size: 24px;
      line-height: 32px;
      margin-top: 16px;
    }
  }

  a {
    color: $label-btn-link;
    padding: 0;
    font-size: $text-sm;
    line-height: 18px;
    font-weight: 600;
    order: 5;

    @include media(sm) {
      margin-top: 8px;
    }
  }

  .body__button_primary {
    display: block;
    text-transform: uppercase;
    text-align: center;
    border-radius: $border-radius-btn;
    padding: 11px 4px !important;
    height: 40px;
    width: 100%;
    max-width: 234px;
    font-weight: 500;
    background: $btn-primary;
    color: $label-btn-primary;
    order: 6;

    @include media(sm) {
      padding: 11px 16px !important;
    }
  }
}
