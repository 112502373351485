@import '../../styles/variables';
@import '../../styles/mixin';

.store__item {
  width: 100%;
  height: fit-content;
  cursor: pointer;
  position: relative;
  border-radius: $store-locator-info-border-radius !important;

  .placeholder {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: $image-border-radius;
    background-color: $placeholder-bg;

    div {
      position: absolute;
      width: 100%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    svg {
      margin: 0 auto;
      height: 18px;
      width: 18px;

      @include media(3xl) {
        height: 30px;
        width: 30px;
      }
    }

    p {
      width: 100%;
      margin-top: 10px;
      text-align: center;
      font-size: $text-xs;
      color: $gray-300 !important;

      @include media(3xl) {
        font-size: $text-base;
      }
    }
  }

  .store__image {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 54.35%;

    @media screen and (min-width: 520px) and (max-width: 620px) {
      padding-top: 66.10%;
    }

    img {
      height: auto;
      border-radius: $store-locator-info-border-radius !important;
    }
  }

  .overlay__container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .overlay {
    position: relative;
    display: block;
    height: 100%;

    &_effect_shadow {
      opacity: 1;
      border-radius: $store-locator-info-border-radius;
      background-color: transparent;
      background: linear-gradient(
        180deg,
        rgba(1, 2, 3, 0) 0%,
        rgba(1, 2, 3, 0.9) 100%
      );
    }
  }

  .store__body {
    position: absolute;
    width: -webkit-fill-available;
    left: 0;
    bottom: 0;
    z-index: 2;
    padding: 0 20px 20px;
  }

  .store__btn_select {
    background-color: rgba(13, 13, 13, 0.3);
    position: absolute;
    padding: 4px;
    border-radius: 50%;
    top: 16px;
    right: 16px;
    z-index: 4;

    @media screen and (min-width: 360px) {
      top: 12px;
      right: 12px;
    }

    svg {
      width: 28px;
      height: 28px;

      * {
        stroke: $white;
      }
    }
  }

  .store__text {
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
    row-gap: 4px;

    .store__info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: -webkit-fill-available;
    }

    .store__status {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .store__status_badge {
      border: 1px solid $store-status-open-border-color;
      color: $store-status-open-text-color;
      background-color: $store-status-open-color;
      border-radius: $store-status-border-radius;
      padding: 0.25rem 0.5rem;
      width: fit-content;
      font-size: $text-xs;
      line-height: 16px;

      &_closed {
        border: 1px solid $store-status-closed-border-color;
        color: $store-status-closed-text-color;
        background-color: $store-status-closed-color;
      }
    }

    .store__status_message {
      color: $white;
      font-size: $text-sm;
    }

    .elipsi__text {
      overflow: hidden;
      text-align: left;
      max-width: fit-content;
      text-overflow: ellipsis;
      white-space: nowrap;

      @supports (-webkit-line-clamp: 1) {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
      }
    }

    .store__phone {
      @extend .elipsi__text;
      font-size: $text-sm;
      line-height: 24px;
      color: $white;

      @media screen and (min-width: 360px) {
        margin-top: 8px;
      }
    }

    .store__addres {
      @extend .elipsi__text;
      font-size: $text-sm;
      line-height: 24px;
      color: $white;
    }

    h2 {
      @extend .elipsi__text;
      font-size: $text-xl;
      line-height: 24px;
      font-weight: 500;
      color: $white;

      @media screen and (min-width: 640px) and (max-width: 767px) {
        font-size: $text-base;
        line-height: 20px;
      }
    }
  }
}

// .store-list__item {
//   display: flex;
//   flex-direction: column;

//   &:not(:last-child) {
//     margin-bottom: 1.5rem;
//   }
//   &__without_bordered {
//     margin-bottom: 0 !important;
//   }
//   .store-item__current {
//     padding: 0.44rem 1rem;
//     background: $btn-primary;
//     color: $label-btn-primary;
//     border-radius: $border-radius;
//     text-transform: uppercase;
//     font-weight: 500;
//     font-size: $text-sm;

//     &.with-border {
//       border-radius: $border-radius-md $border-radius-md 0 0;
//     }
//   }

//   .store-item__content {
//     display: flex;
//     flex-direction: column;
//     padding: 1rem 1rem 0px 1rem;
//     border-radius: $border-radius-md;

//     &.with-border {
//       border: 1px solid $gray-200;
//     }

//     .store-item__content-name {
//       color: $gray-600;
//       font-weight: 500;
//       font-size: $text-base;
//       line-height: 24px;
//       margin-bottom: 0.5rem;
//     }

//     .store-item__location-placeholder {
//       height: 18px;
//       width: 100%;
//       color: $gray-400;
//       margin-bottom: 1rem;
//       border-radius: 10px;
//     }

//     .store-item__content-miles {
//       color: $gray-400;
//       font-size: $text-sm;
//       line-height: 18px;
//       margin-bottom: 1rem;
//       font-weight: 400;
//     }

//     .store-item__content-address,
//     .store-item__content-phone {
//       color: $gray-600;
//       font-size: $text-base;
//       line-height: 24px;
//       margin-bottom: 0.5rem;
//       font-weight: 400;
//       cursor: pointer;
//     }

//     .store-item__content-opening {
//       display: flex;
//       align-items: center;
//       margin-bottom: 2rem;
//       font-size: $text-sm;

//       .store__status {
//         color: $success;

//         &.closed {
//           color: $errors;
//         }
//       }

//       .divider {
//         width: 1px;
//         height: 14px;
//         background: $gray-400;
//         margin: 0 0.5rem;
//       }

//       .store__closing-time {
//         color: $gray-500;
//       }
//     }

//     .store-item__content-details {
//       color: $btn-primary;
//       font-weight: 500;
//       font-size: $text-sm;
//       text-decoration: underline;
//     }

//     .store-item__content-btn {
//       margin-top: 2rem;
//       margin-bottom: 1rem;
//       text-transform: uppercase;
//       color: $label-btn-secondary;
//       font-weight: 500;
//       font-size: $text-sm;
//       line-height: 18px;
//     }
//   }

//   &.is-active {
//     .store-item__content {
//       &.with-border {
//         position: relative;
//         border: 1px solid $primary;
//         border-radius: 0 0 $border-radius-md $border-radius-md;
//         padding: 16px;
//       }
//     }
//   }
//   &.is-target {
//     .store-item__content {
//       border: 3px solid $primary;
//     }
//   }
// }

.store_radio_item {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  padding: 1rem 0;
  width: 100%;
  cursor: pointer;

  .store_radio_header {
    display: flex;
    align-items: center;
    column-gap: 12px;

    input {
      float: left;
      margin-top: 5px;
      margin-right: 0;
      align-self: flex-start;
    }

    p {
      color: $selector-store-name-item;
      font-weight: 500;
    }
  }

  &:not(:last-child) {
    border-bottom: 0.5px solid $selector-store-item-border-bottom;
  }

  .store_radio {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1.5px solid $input-ckek-border-color;
    background-color: $input-ckek-background-color;
    border-radius: 50%;
    height: 16px;
    margin: 0;
    outline: 0;
    position: relative;
    width: 16px;
    min-width: 16px;
    margin-right: 1rem;
    margin-top: 0.15rem;

    &::before {
      content: '';
      width: 8px;
      height: 8px;
      display: none;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      position: absolute;
      cursor: pointer;
    }

    &:checked {
      border-color: $input-check-color;
      &::before {
        background-color: $input-check-color;
        display: inline-block;
      }
    }
  }

  .store_content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .store_content_address,
    .status {
      margin-left: 28px;
      font-size: $text-sm;
      color: $selector-text-address-item;
      line-height: 20px;
      font-weight: 400;
    }

    .status {
      border-left: 0.75px solid $selector-store-status-border-left;
      white-space: nowrap;
      margin-left: 0;
      padding-left: 12px;
      color: $success;

      &.closed {
        color: $errors;
      }
    }
  }
}
