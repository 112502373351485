@import '../../styles/variables';
@import '../../styles/mixin';

.picture__heigth {
  height: 152.14px;

  @media screen and (max-width: 420px) {
    height: 140px;
  }

  @include media(lg) {
    height: 165.42px;
  }
}

.specials {
  display: flex;
  flex-direction: column-reverse;

  @media screen and (max-width: 480px) {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  &__see_all {
    font-weight: 500;
    color: $see-all-link;
    font-size: $text-sm;
    flex-shrink: 0;

    @include media(md) {
      font-size: $text-base;
    }

    span {
      margin-left: 5px;
      color: $see-all-counter !important;
    }
  }

  &__header {
    align-items: center;
    flex-direction: column;
    text-transform: uppercase;
    margin-bottom: 20px;

    @include media(sm) {
      flex-direction: row;
    }

    a {
      color: $secondary-400;
    }
  }

 /*  &__placeholder {
    height: 220px;
    width: 315px;
    margin-right: 20px;
  } */

  .special {
    @extend .picture__heigth;
    position: relative;
    width: 312px;
    border-radius: $specials-card-border-radius;
    overflow: hidden;

    @media screen and (max-width: 420px) {
      width: 288px;
    }

    @include media(lg) {
      width: 340px;
    }

    &__placeholder {
      display: flex;
      flex-direction: column;
      row-gap: 5px;
      height: 212px;
      width: 340px;
      margin-right: 8px;

      &_image,
      &_title,
      &_code {
        background-color: $special-placeholder-color !important;
        width: 100%;
      }

      &_image {
        border-radius: $border-radius;
        height: 170px;
      }

      &_title {
        margin-top: 8px;
        height: 18px;
      }

      &_code {
        height: 18px;
        width: 50%;
      }
    }
  }

  :global(.swiper-slide) {
    width: fit-content;
  }
}
