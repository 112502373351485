@import '../../styles/variables';
@import '../../styles/mixin';

.store_locator__container {
  position: relative;
  flex-shrink: 1;
  width: 100%;
  max-width: 100%;
  min-width: 0;

  @include media(sm) {
    width: 100%;
    min-width: 360px;
    max-width: fit-content;
  }

  &__centered_single_row {
    max-width: fit-content;

    .info {
      display: flex;
      gap: 5px;
    }
  }
}

.store_locator__button {
  align-items: center;
  background-color: $selector-btn-background;
  border: $selector-btn-border;
  border-radius: $border-radius-btn;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 8px;
  width: 100%;
  
  @media (max-width: 1024px) {
    height: 40px !important;
  }

  @include media(lg) {
    height: 48px;
    padding: 11px 16px;
  }

  .button-content {
    align-items: center;
    column-gap: 16px;
    display: flex;
    width: 100%;

    svg {
      * {
        stroke: $selector-icon-color;
      }
    }

    .info {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;

      .address {
        font-weight: 400;
        font-size: 0.8rem;
        line-height: 1rem;
        display: flex;
        align-items: center;
        color: $selector-btn-address-color;
        overflow: hidden;
        text-overflow: ellipsis;

        @include media(lg) {
          font-size: $text-base;
          line-height: 18px;
        }
      }

      .method {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1rem;
        display: flex;
        align-items: center;
        color: $selector-btn-text-color;
        letter-spacing: -0.03px;

        @include media(lg) {
          font-size: $text-sm;
          line-height: 16px;
        }
      }
    }

    i {
      font-size: $text-2xl;
      color: $selector-icon-color;
    }
  }
}

.alternate_header_variation {
  @media screen and (max-width: 1024px) {
    padding: 6px 12px;
    height: 38px;
  }
}

.store__locator_form {
  $form-width: 350px;
  background-color: $background-store-selector;
  box-shadow: $selector-shadow;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  transition: $transition-all;
  z-index: 20;
  position: fixed;
  top: 0px;
  left: 0;
  width: 100vw;
  height: 100%;
  margin-top: 0;

  @include media(sm) {
    height: auto;
    padding: 1.5rem 2.25rem;
    max-height: min(75vh, 767px);
    max-width: 408px;
    position: absolute;
    top: 50px;
    left: -15px;
    margin: 5px $space-x $space-x;
    border-radius: $border-radius;
  }

  &__centered {
    left: 50%;
    transform: translateX(-50%);

    @include media(sm) {
      left: 46.2%;
    }
  }

  &__centered_logo_and_nav {
    @media screen and (min-width: 1024px) and (max-width: 1100px) {
      left: 60%;
    }
  }

  .store__locator_top {
    display: flex;
    flex-direction: column;

    .store__locator_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 700;
      margin-bottom: 1.5rem;

      h6 {
        color: $selector-heading-color;
      }

      .close_button {
        color: $btn-close-icon-color;
        padding: 0;
        height: auto;

        &:hover {
          color: $btn-close-icon-color-hover;
        }

        .close_icon {
          font-size: 1.25rem;
        }
      }
    }
  }

  .store_locator_center {
    flex: 1;
    overflow-y: auto;
    //padding-right: 0.25rem;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 8px;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: $gray-200;
      }
    }

    .delivery_section {
      .delivery_section__title {
        color: $selector-store-mode-title;
        font-weight: 700;
        margin-bottom: 0.5rem;
      }

      label {
        margin-bottom: 1rem;
      }

      .delivery_section__content {
        @include media(sm) {
          overflow-y: auto;
        }

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .pickup_section {
      .pickup_section__title {
        color: $selector-store-mode-title;
        font-weight: 700;
        margin-bottom: 0.5rem;
      }

      .pickup_section__content {
        @include media(sm) {
          overflow-y: auto;
        }

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }

  .store {
    border-bottom: 0.5px solid $gray-100;
    margin-bottom: 0.5rem;
    padding-bottom: 1.5rem;
  }

  .current_store {
    border-top: 0;
    padding-top: 0;
  }

  .current_location {
    display: flex;
    align-items: center;
    color: $selector-current-location-color;
    margin-bottom: 1rem;
    font-weight: 500;
    align-self: flex-start;
    padding: 0;
    font-size: $text-base;
    height: auto !important;

    &.hidden {
      display: none;
    }

    .crosshairs_icon {
      margin-right: 13px;
      font-size: 1.35rem;
      width: 21px;
    }
  }

  .store__locator_bottom {
    grid-row: 3;
    background-color: inherit;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    align-items: center;
    width: 100%;
    padding-top: 1.5rem;
    box-shadow: $box-shadow-3;

    @include media(sm) {
      padding-top: 2rem;
    }

    .shop_button {
      text-transform: uppercase;
      width: 100%;
    }

    .button_location {
      i {
        font-size: $text-xl;
        padding-right: 12px;
      }
    }

    .error_message {
      position: absolute;
      left: 0;
      top: 25.5rem;
      font-size: 80%;
      color: $input-invalid-color;
      width: 100%;
      background-color: $white;
      padding: 0.75rem 1.75rem;
    }
  }
}

.shop_mode {
  display: flex;
  padding: 0.25rem;
  height: 64px;
  width: 100%;
  background: $selector-mode-bg;
  border-radius: $selector-mode-border-radius;
  border: 1px solid $selector-mode-border-color;
  position: relative;
  margin-bottom: 1rem;

  &:hover,
  &:focus {
    border: 1px solid $selector-mode-border-color-hover;
  }

  .shop_mode__background {
    height: calc(100% - 8px);
    width: calc(50% - 4px);
    background-color: $background-btn-mode;
    position: absolute;
    left: 4px;
    border-radius: calc($selector-mode-border-radius - 2px);
    transform: translateX(0);
    transition: transform .4s ease;

    &.delivery {
      transform: translateX(0);
    }

    &.pickup {
      transform: translateX(100%);
    }
  }

  .delivery_mode,
  .pickup_mode {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: calc($selector-mode-border-radius - 2px);
    flex: 1;
    height: 100%;
    font-weight: 500;
    cursor: pointer;
    z-index: 2;
    color: $label-btn-mode-secondary;

    &.selected {
      background: $background-btn-mode;
      border: 1px solid $background-btn-mode;
      color: $label-btn-mode;

      svg {
        * {
          stroke: $label-btn-mode;
          width: 24px;
          height: 24px;
        }
      }

      &:hover, &:focus  {
        $color-select: get-value($label-btn-mode, $label-btn-mode-active);
        border: 1px solid $border-color-btn-mode-active;
        background: $background-btn-mode-active;
        color: $color-select;

        svg {
          * {
            stroke: $color-select;
          }
        }
      }
    }

    div {
      gap: 0.5rem;
      align-items: center;

      svg {
        display: block;
        flex-shrink: 0;
        * {
          stroke: $label-btn-mode-secondary;
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}

.store_locator__overlay {
  background: $overlay-bg;
  bottom: 0;
  cursor: not-allowed;
  left: 0;
  opacity: 1;
  pointer-events: initial;
  position: fixed;
  right: 0;
  top: 0;
  visibility: visible;
  z-index: 11;
}
