@import '../../styles/variables';
@import '../../styles/mixin';

.ratingreviews {
  position: relative;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1.5fr);
  grid-template-rows: 40px 200px;
  column-gap: 30px;
  align-items: center;

  .rating__heading {
    position: relative;
    color: $gray-600;
    font-size: $text-xl;
    font-weight: 700;
    line-height: 24px;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    margin-top: 0px;
  }

  .rating__puntuation {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 14;
    line-height: 18px;
    font-weight: 400;
    color: $gray-300;
    margin-top: 33px;

    strong {
      font-weight: 700;
      font-size: 56px;
      line-height: 68px;
      color: $states-btn-rating-color;
      margin-bottom: 15.57px;
    }

    .rating__card {
      justify-content: space-between;
    }

    [class~='rating'] {
      margin-bottom: 16.07px;
    }

    [class~='icon'] {
      font-size: $text-base;
      margin-right: 8.89px;
      color: $gray-200;
      width: 10px;
      height: 16.36px;

      @include media(sm) {
        font-size: $text-xl;
        width: 17.11px;
      }
    }

    i[class~='icon_star'] {
      color: $rating-review-color;
    }

    i[class~='icon_star-half-alt'] {
      color: $rating-review-color;
    }
  }

  ol {
    display: flex;
    flex-direction: column;
    gap: 15.64px;
    margin-top: 27.57px;

    .rating__li-review {
      display: flex;
      align-items: center;
      align-content: center;

      [class~='icon'] {
        font-size: $text-xl;
        width: 17.11px;
        height: 16.36px;
        margin-right: 10px;
        color: $rating-review-color;
        align-self: center;
      }

      .rating__meter-bar {
        width: 148px;
        height: 4px;
        background: $gray-100;
        border: 1px solid $gray-100;
        border-radius: $border-radius-sm;
        margin: 0px 16px 0px 8px;
        align-items: center;
        display: flex;

        .rating__progress {
          background: $rating-review-bar-color;
          height: 4px;
          border-radius: $border-radius-sm 0 0 $border-radius-sm;

          &_full {
            border-radius: $border-radius-sm;
          }
        }
      }

      span {
        width: 40px;
        text-align: center;
      }

      .rating__number {
        width: 15px;
      }
    }
  }
}
