@import '../../styles/variables';
@import '../../styles/mixin';

.location__reviews-title {
  display: flex;
  line-height: 56px;
  align-items: center;
  position: relative;

  [class~='slick-prev'] {
    position: absolute;
    top: 52%;
    transform: translate(0, -52%);
    display: none !important;
    border: 1px solid $gray-200;
    width: 52.99px;
    border-right: 0;

    @include media(sm) {
      display: block !important;
    }
  }

  [class~='slick-next'] {
    width: 15.58px;
    height: 15.18px;
    top: 40%;
    transform: translate(0, -41%);
    display: none !important;
    width: 52.99px;
    border-left: 0;

    @include media(sm) {
      display: block !important;
    }
  }
}

.location__reviews-flex_container {
  width: 100%;
  height: auto;
  display: flex;
  gap: 24px;
}

.reviews_title {
  margin-bottom: 20px;
}
.reviews_title p {
  font-size: $text-2xl;
  font-weight: 700;
}

.reviews-card {
  flex: 0 0 auto;
  padding: 24px;
  width: 88vw;
  height: auto;
  display: flex;
  flex-direction: column;

  @include media(md) {
    width: 360px;
  }

  @include media(lg) {
    width: 475px;
  }

  @include media(xl) {
    width: 385px;
  }

  @include media(2xl) {
    width: 475px;
  }
}

.card_title {
  margin-bottom: 18px;
  font-weight: 700;
  font-size: $text-lg;
  line-height: 24px;
  color: $black;
}

.card_stars {
  display: flex;
  gap: 10.88px;
  padding-bottom: 19px;
  color: $secondary-400;

  [class~='icon'] {
    font-size: $text-xl;
    color: $gray-200;
    width: 17.11px;
    height: 16.36px;
  }

  i[class~='icon_star'] {
    color: $rating-review-color;
  }

  i[class~='icon_star-half-alt'] {
    color: $rating-review-color;
  }
}

.card_desc {
  font-weight: 400;
  font-size: $text-base;
  line-height: 24px;
  color: $gray-500;
}

.person_info {
  margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 15px;
}

.person_desc {
  display: flex;
  flex-direction: column;
}

.person_name {
  font-weight: 700;
  font-size: $text-base;
  line-height: 24px;
  color: $gray-600;
}

.person_position {
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
}

.person_from {
  line-height: 24px;
}

.person_img {
  position: relative;
  width: 64px;
  height: 64px;
  overflow: hidden;
  border-radius: 50%;
  background-color: $primary-200;
}

.person_img > img {
  position: relative;
  width: 64px;
  height: 64px;
}
