@import '../../styles/variables';
@import '../../styles/mixin';

.slider__container {
  &_not_padding_mobile {
    @media screen and (max-width: 480px) {
      padding: 0 !important;
    }
  }

  /* :global(.swiper) {
    width: -webkit-fill-available;
    width: -moz-available;
  } */
}

.slider {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  width: auto;

  &__height_short {
    padding-bottom: 0;
  }

  .slider_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    &_right {
      justify-content: flex-end;
    }

    &_padding_mobile {
      @media screen and (max-width: 480px) {
        padding: 0 15px 0px 15px;
      }
    }

    &--hide {
      display: none;
    }

    &--showSlidesOutsideContainer {
      @include container;
    }

    h2 {
      margin-right: 1rem;
    }

    .slider__shop_all {
      display: flex;
      align-items: center;
      min-width: fit-content;
      padding: 0 1px;
      gap: 16px;

      @include media(sm) {
        gap: 23px;
      }

      .slider__see_all {
        position: relative;
        font-size: $text-sm;
        font-weight: 500;
        line-height: 2rem;
        color: $see-all-link;
        cursor: pointer;
        flex-shrink: 0;

        span {
          margin-left: 5px;
          color: $gray-300;
        }

        @include media(md) {
          font-size: $text-base;
        }
      }
    }

    .slider_buttons {
      display: none;

      @include media(sm) {
        display: flex;
      }
    }
  }

  .slider_item {
    display: flex !important;
    width: auto;
    height: auto;
    cursor: pointer;
  }

  .slider_item:last-of-type {
    &:hover {
      box-shadow: none;
    }
  }
}

.slider__products {
  @include media(sm) {
    margin-left: -15px;
    padding-left: 15px;
  }

  .slider_item {
    width: 33%;
    margin-top: $product-top-spacing;
    margin-bottom: $product-bottom-spacing;
    border-radius: $border-radius-card;

    @media screen and (max-width: 542px) {
      width: 50%;
    }

    @include media(lg){
      width: 24%;
    }

    @include media(xl){
      width: 19%;
    }

    @include media(2xl){
      width: 238px;
    }

    @include media(3xl){
      width: 238px;
    }
  }

  &_large {
    .slider_item {
      width: 50%;

      @media screen and (max-width: 420px) {
        width: 100%;
      }

      @include media(sm){
        width: 33%;
      }

      @include media(xl){
        width: 24%;
      }
    }
  }
}
