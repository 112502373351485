@import '../../styles/variables';
@import '../../styles/mixin';

.special_bundle_progress__container {
  position: relative;
  width: 100%;

  @include media(sm) {
    max-width: 500px;
  }

  @include media(lg) {
    width: 40%;
    max-width: 400px;
  }

  .special_bundle_progress {
    display: flex;
    align-items: center;
    padding: 1.25rem 1rem;
    gap: 0.75rem;
    border-radius: 0.75rem;
    border: 1px solid $bundle-specials-border-active;
    cursor: pointer;

    &.disabled {
      cursor: default;
      border: 1px solid $bundle-specials-border-default;
    }

    .special_bundle_progress__icon {
      svg {
        * {
          stroke: $bundle-special-icon-default;
        }
      }

      &.special_bundle_progress__icon__complete {
        svg {
          * {
            &:first-child {
              fill: $bundle-special-icon-active !important;
            }
            stroke: $bundle-special-icon-active !important;
          }
        }
      }
    }

    .special_bundle_progress__content {
      flex-grow: 1;

      progress.special_bundle_progress__bar[value] {
        /* Reset the default appearance */
        -webkit-appearance: none;
        appearance: none;
        width: 100%;
        height: 8px;
        border-radius: 6px;

        &::-webkit-progress-bar {
          background-color: $bundle-progress-background;
          border-radius: 6px;
        }

        &::-webkit-progress-value {
          background-color: get-value($bundle-progress-color, $special-bundle-progress-color);
          border-radius: 6px;
        }
      }

      progress.special_bundle_progress__bar_complete[value] {
        &::-webkit-progress-value {
          background-color: get-value($bundle-progress-color-completed, $special-bundle-progress-color-completed);
          border-radius: 6px;
        }
      }

      .special_bundle_progress__title {
        font-size: $text-sm;
        color: $bundle-spacials-descriptions;

        @include media(sm) {
          font-size: $text-base;
        }
      }

      .special_bundle_progress__description {
        font-size: $text-xs;
        font-weight: 400;
        color: $bundle-spacials-descriptions;
      }
    }

    .special_bundle__dropdown_icon {
      margin-top: 0.25rem;
      width: 24px;
      height: 24px;
      transition: transform 0.25s ease-in-out;

      svg {
        height: 24px;
        width: 24px;
        * {
          stroke: $bundle-special-dropdown-icon !important;
        }
      }

      &.special_bundle__dropdown_icon__open {
        transform: rotate(90deg);
      }
    }
  }

  .special_bundle__panel {
    position: absolute;
    top: 120px;
    width: 100%;
    right: 0;
    z-index: 3;
    background-color: $select-menu-bg;
    border: 0.5px solid $select-menu-border-color;
    border-radius: $select-menu-border-radius;
    box-shadow: $select-menu-item-shadow;
    padding-block: 1.25rem;
    border-radius: 0.75rem;

    @include media(lg) {
      width: 450px;
    }

    .special_bundle__panel__body {
      padding-inline: 1.25rem;
      max-height: 420px;
      overflow-y: auto;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $gray-200;
        border-radius: $select-menu-border-radius;
      }
    }
  }
}
