@import '../../styles/variables';
@import '../../styles/mixin';

.counter {
  align-self: center;
  column-gap: 5px;
  display: flex;
  height: min-content;
  justify-content: space-between;
  position: relative;
  max-width: 305px;
  width: 100%;
  background-position: center;
  background-repeat: repeat-x;
  background-size: 11px 1.5px;
  background-image: linear-gradient(
    to right,
    $bundle-specials-border-default 80%,
    transparent 50%
  );

  @include media(lg) {
    column-gap: 12px;
  }

  &--allowDiscount {
    background-image: none !important;
    justify-content: center;

    @include media(lg) {
      justify-content: right;
    }
  }

  &--active {
    background-image: linear-gradient(
      to right,
      $bundle-specials-border-active 80%,
      transparent 50%
    );
  }

  .step {
    align-items: center;
    position: relative;
    display: flex;
    height: 40px;
    justify-content: center;
    position: relative;
    width: 40px;
    background-color: $bundle-specials-background;
    border: $bundle-specials-step-border solid $bundle-specials-border-default;
    border-radius: $border-radius-pill !important;
    height: 80px;
    width: 80px;

    @include media(sm) {
      height: 88px;
      width: 88px;
    }

    svg {
      * {
        stroke: $bundle-special-icon-default;
      }
    }

    &--completed {
      border-color:  $bundle-specials-border-active;

      svg {
        * {
          stroke: $bundle-special-icon-active;
        }
      }
    }

    &--process {
      background: $bundle-specials-background;

      svg {
        * {
          stroke: $bundle-special-icon-active;
        }
      }
    }

    &__icon {
      font-size: $text-4xl;
    }

    .counter {
      color: $bundle-badge-text-color !important;
      align-items: center;
      background:  $bundle-badge-background;
      border-radius: 50%;
      display: flex;
      height: 32px;
      justify-content: center;
      position: absolute;
      right: 7px;
      text-align: center;
      top: 6px;
      width: 32px;

      @include media(sm) {
        top: 10px;
        right: 11px;
      }
    }
  }

  .divider {
    --progress-color: #{$bundle-progress-color}; 
    --progress-color-completed: #{$bundle-progress-color-completed}; 
    align-items: center;
    align-self: center;
    background: $bundle-specials-background;
    border: 1px solid $bundle-specials-border-default;
    border-radius: 50%;
    display: flex;
    height: 40px;
    justify-content: center;
    width: 40px;

    &--process {
      border-color:  $bundle-specials-border-active;

      svg {
        * {
          stroke: $bundle-special-icon-active;
        }
      }

      &::after,
      &::before {
        color: $bundle-specials-border-active;
      }
    }

    &--complete {
      svg {
        * {
          stroke: $bundle-special-icon-active;
        }
      }
    }
  }
}
