@import '../../styles/variables';
@import '../../styles/mixin';

.subscribe__container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  @include media(2xl) {
    width: 80%;
    max-width: 1536px;
    margin: 0 auto;
  }

  .header {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 16px;

    h2 {
      color: $gray-500;
      font-weight: 700;
      letter-spacing: -0.02px;
      font-size: calc(1.153rem + 1.3vw) !important;
      line-height: calc(1.653rem + 1.3vw) !important;

      @include media(lg) {
        font-size: 2rem !important;
        line-height: 40px !important;
      }

      @include media(3xl) {
        font-size: $text-4xl !important;
        line-height: 48px !important;
      }
    }

    p {
      font-weight: 400;
      font-size: $text-base;
      line-height: 24px;
      letter-spacing: -0.02px;

      @include media(md) {
        max-width: 814px;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    width: 100%;

    @media screen and (max-width: 520px) {
      row-gap: 12px;
    }

    div[class~='alert'] {
      margin-bottom: 0 !important;
      width: 100%;
    }

    .subscribe__form {
      display: flex;
      gap: 16px;
      flex-direction: column;
      width: 100%;
      padding: 0;

      @media screen and (min-width: 520px) {
        flex-direction: row;
      }

      @include media(xl) {
        gap: 24px;
      }

      @include media(3xl) {
        gap: 27px;
      }

      label {
        height: 48px;
        flex-grow: 1;

        input {
          height: 48px;
          width: 100%;
          padding: 5px 16px 7px;

          .input_input__control__5IFUO {
            border-color: $gray-200;
          }
        }
      }

      .subscribe__button {
        width: 100%;
        flex-grow: 1;
        height: 48px;

        @media screen and (min-width: 520px) {
          max-width: 234px;
        }

        @include media(xl) {
          max-width: 291px;
        }

        @include media(xl) {
          max-width: 354px;
        }

        @include media(3xl) {
          max-width: 364px;
        }
      }
    }

    &__message {
      text-align: center;
      p + p {
        margin-top: 10px;
      }
    }

    .subcription__text {
      font-size: $text-base;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      padding: 0 10px;

      @media screen and (min-width: 520px) {
        max-width: 400px;
        padding: 0;
      }
    }
    .subcription_theme_color {
      color: $gray-400 !important;
    }
  }

  &_align_right {
    align-items: flex-start;

    @include media(lg) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      column-gap: 20px;
    }

    @include media(xl) {
      column-gap: 40px;
    }

    .header {
      p,
      h2 {
        @media screen and (max-width: 1024px) {
          max-width: 100% !important;
        }
      }

      p {
        @include media(xl) {
          max-width: 495px;
        }
      }
    }

    .content {
      @include media(lg) {
        min-width: 484px;
      }

      @include media(xl) {
        min-width: 609px;
      }

      @include media(2xl) {
        min-width: 735px;
      }

      @include media(3xl) {
        min-width: 758px;
      }

      &__message {
        @include media(sm) {
          text-align: left;
          p + p {
            margin-top: 10px;
          }
        }
      }
    }
  }

  &_align_center {
    align-items: center;
    row-gap: 40px;
    //text-align: center;

    .header {
      align-items: center;
      text-align: center;

      h2 {
        @include media(2xl) {
          font-size: $text-4xl !important;
          line-height: 44px !important;
        }

        @include media(3xl) {
          font-size: 2.5rem !important;
          line-height: 48px !important;
        }
      }

      p {
        @include media(xl) {
          font-size: $text-xl;
          line-height: 28px;
        }
      }

      h2 {
        @media screen and (max-width: 1268px) {
          max-width: 70% !important;
        }

        @media screen and (max-width: 768px) {
          max-width: 100% !important;
        }

        @media screen and (min-width: 1268px) {
          max-width: 70%;
        }
      }

      p {
        @media screen and (max-width: 1268px) {
          max-width: 60% !important;
        }

        @media screen and (max-width: 768px) {
          max-width: 100% !important;
        }

        @media screen and (min-width: 1268px) {
          max-width: 60%;
        }
      }
    }

    .content {
      flex-grow: 1;
      max-width: 495px !important;
      align-items: center;

      .subscribe__form {
        @media screen and (min-width: 428px) {
          flex-direction: row;
        }
        .subscribe__button {
          @media screen and (min-width: 428px) {
            max-width: 126px !important;
          }

          @include media(sm) {
            max-width: 188px !important;
          }
        }
      }
    }
  }
}
