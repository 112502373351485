@import '../../styles/variables';
@import '../../styles/mixin';

.separator {
  width: 100%;
  left: 0;
  right: 0;
  padding: 0;
  display: block;
  z-index: 3;
  background-color: transparent;
}