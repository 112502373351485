@import '../../styles/variables';
@import '../../styles/mixin';

:global(.dark-theme) {
  .reviews {
    border-bottom: 0.5px solid $detail-divider;
  }
}

.reviews {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  column-gap: 10p;
  font-size: $text-base;
  line-height: 24px;
  font-weight: 400;
  border-bottom: 0.5px solid $detail-divider-default;
  margin-bottom: 40px;
  //text-align: center;

  @include media(sm) {
    flex-wrap: nowrap;
    text-align: center;
  }

  @include media(md) {
    flex-direction: row;
  }

  .review_user {
    display: flex;
    width: 100%;
    color: $gray-600;
    order: 1;
    flex-grow: 1;
    text-align: left;

    @include media(md) {
      order: 0;
      padding-left: 1rem;
      width: 50%;
    }

    figure {
      height: fit-content;
      margin-top: 0.5rem;
    }

    .review_user__info {
      padding-left: 1rem;
      display: flex;
      flex-direction: column;

      .review_verified {
        display: flex;
        align-items: center;
        margin-top: 0.25rem;

        i {
          font-size: 0.75rem;
          padding: 0.25rem;
          background-color: $secondary;
          color: $white;
          border-radius: 50%;
          margin-right: 0.25rem;
        }
      }
    }
  }

  time {
    width: 100%;
    color: $gray-300;
    order: 0;
    flex-grow: 1;
    text-align: right;

    @include media(md) {
      width: 50%;
      order: 2;
    }
  }

  .review__content {
    width: 100%;
    display: block;
    order: 2;
    flex-grow: 2;
    margin-top: 35px;

    @include media(sm) {
      order: 1;
      text-align: justify;
    }

    @include media(md) {
      margin-top: 0px;
    }

    div[class~='rating'] {
      margin-bottom: 20.07px;

      @include media(sm) {
        margin-bottom: 0px;
      }
    }

    [class~='icon'] {
      font-size: $text-xl;
      margin-right: 8.89px;
      color: $gray-200;
      width: 17.11px;
      height: 16.36px;
    }

    i[class~='icon_star'] {
      color: $rating-review-color;
    }

    i[class~='icon_star-half-alt'] {
      color: $rating-review-color;
    }

    blockquote {
      color: $gray-500;
      margin-bottom: 1rem;
    }

    .filters_icons {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 2rem;
      gap: 0.75rem;

      .filters_icons__item {
        font-size: 2rem !important;
        color: darken($secondary, 20%) !important;
        font-weight: 500;
      }
    }
  }
}
