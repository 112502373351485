@import '../../styles/variables';
@import '../../styles/mixin';

.select {
  font-size: $text-sm;
  color: $black;
  width: 100%;
  position: relative;

  .select__trigger {
    height: 48px;
    width: 100%;
    border: 1px solid $select-menu-toogle-border;
    border-radius: $border-radius-input;
    font-size: $text-base;
    text-align: left;
    padding: 10px 18px;
    background: $input-bg;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    @include media(lg){
      height: 56px;
    }

    &.select__invalid {
      border-color: $input-invalid-color;
    }

    &:hover {
      border: 1px solid $select-menu-toogle-border-hover;
    }

    &.selected {
      .select__label {
        opacity: 100%;
      }
    }

    .select__label {
      flex: 1;
      padding-right: 0.25rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $gray-500;
      opacity: 50%;
    }

    .select__icon {
      font-size: $text-xl;
      color: $filter-icon-color;
    }
  }

  .select__menu {
    width: 100%;
    min-width: fit-content;
    color: $select-menu-item-color !important;
    background: $select-menu-bg !important;
    border: 0.5px solid $select-menu-border-color !important;
    border-radius: $select-menu-border-radius;
    box-shadow: $select-menu-item-shadow;
    flex-direction: column;
    padding: 1rem 0;
    z-index: 2;

    .select__menu_body {
      width: 100%;
      height: 100%;
      max-height: 240px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $selct-thumb-color;
        border-radius: $select-menu-border-radius;
      }

    }

    .select__menu-item {
      white-space: nowrap;
      cursor: pointer;
      padding: 0.65rem 1rem;
      font-size: $text-base;

      &:hover {
        color: $select-menu-item-color-hover;
        background: $select-menu-item-bg-hover;
      }

      &.is-selected {
        color: $select-menu-item-color-select;
        background: $select-menu-item-bg-select;
      }
    }
  }

  .select__feedback {
    font-size: 80%;
    text-align: left;
    color: $input-invalid-color;
    margin-top: 4px;
  }
}
