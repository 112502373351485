@import '../../styles/variables';
@import '../../styles/mixin';

.promotion_card {
  position: relative;
  overflow: hidden;
  padding-top: 49.02%;
  width: 100%;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 2;
  // width: 55%; //new design
  width: 60%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.description {
  font-weight: 700;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  @media screen and (max-width: 640px) {
    font-size: $text-sm;
  }

  h3 {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: $text-base;
    line-height: 1.25rem;

    @media screen and (max-width: 640px) {
      font-size: $text-sm;
      -webkit-line-clamp: 2;
      line-clamp: 2;
    }
  }

  .extra_description {
    font-size: $text-xs;
    font-weight: 400;
    line-height: 0.9rem;

    p {
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      display: -webkit-box;
      overflow: hidden;
      line-clamp: 3;
      text-overflow: ellipsis;

      @media screen and (max-width: 640px) {
        -webkit-line-clamp: 2;
        line-clamp: 2;
      }
    }
  }
}

.cta_button {
  padding: 4px 8px;
  width: max-content;
  cursor: pointer;
  font-size: $text-xs;
  border-radius: $border-radius-btn;
  text-transform: uppercase;

  @media screen and (max-width: 640px) {
    font-size: 0.625rem;
    padding: 2px 6px;
  }
}

.picture {
  height: 100%;
  overflow: hidden;
  width: 100%;

  > span {
    border-radius: $specials-card-border-radius;
  }

  img {
    height: auto;
    object-fit: contain;
  }
}

.picture_small {
  //border-bottom-left-radius: 65% 130px; //new design
  //border-top-left-radius: 65% 130px; //new design
  background: inherit;
  overflow: hidden;
  position: absolute;
  height: 100%;
  top:0;
  right: 0;
  width: 100%;
  z-index: 1;
  //width: 45%; //new design

  > span {
    border-radius: $specials-card-border-radius;
  }

  img {
    height: auto;
    object-fit: cover;
    object-position: right;
  }
}
